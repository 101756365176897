import { ref } from 'vue'

export function useNumberFormatter() {
  const locale = ref('pt-BR')

  const numberFormat = (number, options = {}) => {
    const formatter = new Intl.NumberFormat(locale.value, {
      minimumFractionDigits: 1,
      maximumFractionDigits: 2,
      ...options
    })
    return formatter.format(number)
  }

  return { numberFormat }
}
