<script>
import { onMounted, ref, watch } from 'vue'
import { useMeta } from 'vue-meta'

import formDate from '@/components/form/date.vue'
import formInput from '@/components/form/input.vue'
import formSelect from '@/components/form/select.vue'
import formModalPos from '@/components/modalOperation/formModalPos.vue'
import appLoading from '@/components/shared/loading.vue'
import appModal from '@/components/shared/modal.vue'
import appTable from '@/components/shared/table.vue'
import { useInfiniteScroll } from '@/composables/utils/useInfiniteScroll'
import { useNoScroll } from '@/composables/utils/useNoScroll'
import { useOperationPendency } from '@/composables/utils/useOperationPendency'
import { useOperationType } from '@/composables/utils/useOperationType'
import { usePagination } from '@/composables/utils/usePagination'
import { usePermissions } from '@/composables/utils/usePermissions'
import { useSearchThrottle } from '@/composables/utils/useSearch'
import router from '@/router'
import {
  fetchPosOperations,
  createPosOperation,
  updatePosOperation,
  fetchPosOperation,
  deletePosOperation
} from '@/services/api'
import { handleGlobalError } from '@/services/errorHandler'
import { useWss } from '@/stores/wss'

export default {
  name: 'PosOperations',

  components: {
    formInput,
    formSelect,
    formDate,
    appLoading,
    appTable,
    formModalPos,
    appModal
  },

  props: {
    search: String,
    status: String,
    type: String,
    closedAt: String,
    id: String
  },

  setup(props) {
    useMeta({ title: 'Pós-operação' })
    const modalFormIsShown = ref(false)
    const modalConfig = ref({})

    const { totalPages, currentPage, isLastPage, setPagination, nextPage } = usePagination()

    const searchValue = ref(props.search)
    const natureTypeValue = ref(props.type)
    const pendencyValue = ref(props.status)
    const closingValue = ref(props.closedAt)
    const idEditOperation = ref(props.id)

    const valuesToWatch = [natureTypeValue, pendencyValue, closingValue]

    const permissions = usePermissions()

    const operations = ref('')
    const loading = ref(false)

    useSearchThrottle(searchValue, () => {
      resetAndUpdateOperations()
    })

    const { setNoScroll } = useNoScroll()

    const { fetchPendencyOptions } = useOperationPendency()
    const { fetchOperationTypeOptions } = useOperationType()

    const operationOptions = fetchOperationTypeOptions()
    const pendencyOptions = fetchPendencyOptions()

    const tableHeaders = [
      { key: 'clientName', label: 'Cliente', type: 'text' },
      {
        key: 'principalAmount',
        label: 'Valor',
        type: 'currency',
        locale: 'pt-BR',
        maximumFractionDigits: 2
      },
      { key: 'type', label: 'Tipo', type: 'operationType' },
      { key: 'status', label: 'Pendência', type: 'pendencyType' },
      {
        key: 'commercialQuote',
        label: 'COT. COM.',
        type: 'quotation',
        locale: 'pt-BR',
        maximumFractionDigits: 4
      },
      { key: 'spread', label: 'Spread', type: 'spread' },
      {
        key: 'clientQuote',
        label: 'COT. CLIEN.',
        type: 'quotation',
        locale: 'pt-BR',
        maximumFractionDigits: 4
      },
      {
        key: 'createdAt',
        label: 'Fechamento',
        type: 'date',
        format: 'dd/MM/yyyy HH:mm',
        timeZoneOffset: -3
      },
      {
        key: 'actions',
        label: 'Ações',
        type: 'actions',
        options: [
          { action: 'edit', label: 'Editar', class: 'edit', emitEvent: 'edit' },
          {
            action: 'delete',
            label: 'Deletar',
            class: 'delete',
            emitEvent: 'delete',
            isVisible: permissions.canDeleteData()
          }
        ]
      }
    ]

    const fetchOperations = async (config) => {
      loading.value = true
      try {
        const response = await fetchPosOperations(config)
        operations.value =
          currentPage.value === 0
            ? response.data.payload
            : [...operations.value, ...response.data.payload]

        setPagination(response.data.page.pageCount, currentPage.value)
      } catch (e) {
        handleGlobalError(e)
      } finally {
        loading.value = false
      }
    }

    useInfiniteScroll(() => {
      if (
        !loading.value &&
        operations.value.length !== 0 &&
        currentPage.value < totalPages.value - 1
      ) {
        nextPage()
        updateOperationsWithFilters()
      }
    })

    const resetOperations = () => {
      operations.value = ''
      currentPage.value = 0
    }

    const updateOperationsWithFilters = () => {
      const config = {
        page: currentPage.value,
        search: searchValue.value,
        status: pendencyValue.value,
        type: natureTypeValue.value,
        closedAt: closingValue.value
      }

      const queryParams = { ...config }
      delete queryParams.page
      router.push({ name: 'PosOperations', query: queryParams })

      fetchOperations(config)
    }

    const resetAndUpdateOperations = () => {
      resetOperations()
      updateOperationsWithFilters()
    }

    const wssStore = useWss()

    watch(
      [() => wssStore.getNewPreOperation.id, () => wssStore.getNewPosOperation.id],
      ([newPreVal, newPostVal], [oldPreVal, oldPostVal]) => {
        if (newPreVal !== oldPreVal || newPostVal !== oldPostVal) {
          resetAndUpdateOperations()
        }
      }
    )

    valuesToWatch.forEach((refValue) => {
      watch(refValue, resetAndUpdateOperations)
    })

    onMounted(() => {
      if (idEditOperation.value) {
        let item = {
          id: idEditOperation.value
        }
        handleEdit(item)
      }

      const config = {
        page: 0,
        search: searchValue.value,
        status: pendencyValue.value,
        type: natureTypeValue.value,
        closedAt: closingValue.value
      }
      fetchOperations(config)
    })

    const handleDelete = async (item) => {
      modalConfig.value = {
        origin: 'delete-target-tax',
        title: 'Você tem certeza?',
        icon: 'bi bi-exclamation-square-fill',
        description: 'Se você remover esta oferta, ela ficará como pós-operação para o cliente',
        showButtons: true,
        type: 'warning',
        submitButtonText: 'Confirmar',
        backButtonText: 'Cancelar',
        id: item.id
      }
      modalFormIsShown.value = true
    }

    const loadingModal = ref(false)

    const handleDeletePosOperation = async (item) => {
      try {
        loadingModal.value = true
        const body = [item.id]
        await deletePosOperation(body)
        operations.value = operations.value.filter((op) => op.id !== item.id)
      } catch (e) {
        handleGlobalError(e)
      } finally {
        modalFormIsShown.value = false
        loadingModal.value = false
        setNoScroll(false)
      }
    }

    const handleConfirm = (item) => {
      if (item.origin === 'delete-target-tax') {
        handleDeletePosOperation(item)
      }
    }

    const title = ref('')
    const modalEnabled = ref(false)
    const editModeEnabled = ref(false)
    const loadingButton = ref(false)
    const initialFormData = ref({})

    const newPosOperation = () => {
      title.value = 'Nova Pós-operação'
      modalEnabled.value = true
    }

    const handleFormCreation = async (formData) => {
      if (!editModeEnabled.value) {
        handleSubmitOperation(formData)
      } else {
        handleUpdateOperation(formData)
      }
    }

    const handleSubmitOperation = async (formData) => {
      try {
        loadingButton.value = true
        await createPosOperation(formData)
        handleFormClose()
        resetAndUpdateOperations()
      } catch (e) {
        handleGlobalError(e)
      } finally {
        loadingButton.value = false
        setNoScroll(false)
      }
    }

    const handleUpdateOperation = async (updatedForm) => {
      try {
        loadingButton.value = true
        await updatePosOperation(updatedForm)
        handleFormClose()
        resetAndUpdateOperations()
      } catch (e) {
        handleGlobalError(e)
      } finally {
        loadingButton.value = false
        setNoScroll(false)
      }
    }

    const handleFormClose = () => {
      initialFormData.value = {}
      editModeEnabled.value = false
      modalEnabled.value = false

      const queryParams = {}
      router.push({ name: 'PosOperations', query: queryParams })
    }

    const handleEdit = async (item) => {
      try {
        let response = await fetchPosOperation(item.id)
        title.value = 'Editar Pós-operação'
        initialFormData.value = {
          ...response.data.payload
        }

        editModeEnabled.value = true
        modalEnabled.value = true

        const config = {
          id: item.id
        }

        const queryParams = { ...config }
        router.push({ name: 'PosOperations', query: queryParams })
      } catch (e) {
        handleGlobalError(e)
      }
    }

    return {
      permissions,
      searchValue,
      natureTypeValue,
      operationOptions,
      pendencyValue,
      pendencyOptions,
      closingValue,

      tableHeaders,
      operations,
      loading,

      modalFormIsShown,
      loadingModal,
      modalConfig,
      handleDelete,
      handleConfirm,

      isLastPage,

      newPosOperation,
      modalEnabled,
      title,
      initialFormData,
      loadingButton,

      handleEdit,

      handleFormClose,
      handleFormCreation
    }
  }
}
</script>

<template>
  <div>
    <app-modal
      v-if="modalFormIsShown"
      :modalConfig="modalConfig"
      :loading="loadingModal"
      @submit="handleConfirm"
      @onClose="modalFormIsShown = false"
    />

    <form-modal-pos
      v-if="modalEnabled"
      :title="title"
      @onClose="handleFormClose"
      :loading="loadingButton"
      :initialFormData="initialFormData"
      @onSubmitted="handleFormCreation"
    />

    <div class="row title">
      <h2 class="col">Pós-operação</h2>
      <div class="col-12 col-md-3 form-input">
        <button v-if="permissions.canCreatedData()" class="secondary" @click="newPosOperation()">
          <i class="bi bi-node-plus-fill"></i>
          Criar Pós-operação
        </button>
      </div>
    </div>

    <div class="row g-2 mt-3">
      <form-input
        class="col form-input"
        label="Cliente"
        v-model="searchValue"
        id="search"
        name="search"
        type="search"
        icon="bi bi-search"
        placeholder="Nome do cliente"
        autocomplete="off"
      />
      <form-select
        class="col form-input"
        label="Tipo de operação"
        v-model="natureTypeValue"
        :items="operationOptions"
        id="operation"
        name="operation"
      />
      <form-select
        class="col form-input"
        label="Pendência"
        v-model="pendencyValue"
        :items="pendencyOptions"
        id="status"
        name="status"
      />
      <form-date
        class="col form-input"
        label="Data de fechamento"
        v-model="closingValue"
        id="closing"
        name="closing"
        icon="bi bi-calendar-week"
      />
    </div>

    <div class="row pb-5">
      <div class="col-12 form-table">
        <app-table
          v-if="operations.length > 0"
          :headers="tableHeaders"
          :items="operations"
          @edit="handleEdit"
          @delete="handleDelete"
        />

        <div v-if="isLastPage && !loading" class="d-flex align-items-center mt-5">
          <h6 class="col mb-0 text-center">Isso é tudo!</h6>
        </div>

        <div
          v-if="operations.length === 0 && !loading"
          class="d-flex align-items-center placeholder"
        >
          <h4 class="col mb-0 text-center">Nada encontrado, tente pesquisar novamente.</h4>
        </div>

        <app-loading v-if="loading" class="pt-5 mt-5 mx-auto" :duration="'2.3s'" />
      </div>
    </div>
  </div>
</template>
