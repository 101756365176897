<script>
import { onMounted, ref } from 'vue'
import { useMeta } from 'vue-meta'

import formModalBroker from '@/components/modalBroker/formModalBroker.vue'
import appLoading from '@/components/shared/loading.vue'
import appTable from '@/components/shared/table.vue'
import { useNoScroll } from '@/composables/utils/useNoScroll'
import { fetchBrokers, putUpdateBroker } from '@/services/api'
import { handleGlobalError } from '@/services/errorHandler'

export default {
  name: 'appBrokers',

  components: {
    appLoading,
    appTable,
    formModalBroker
  },

  setup() {
    useMeta({ title: 'Brokers' })

    const brokers = ref('')
    const loading = ref(false)

    const { setNoScroll } = useNoScroll()

    const tableHeaders = [
      { key: 'name', label: 'Nome', type: 'text' },
      {
        key: 'actions',
        label: 'Ações',
        type: 'actions',
        options: [{ action: 'edit', label: 'Editar', class: 'edit', emitEvent: 'edit' }]
      }
    ]

    const fetchOperations = async () => {
      loading.value = true
      try {
        const response = await fetchBrokers()
        brokers.value = response.data.payload
      } catch (e) {
        handleGlobalError(e)
      } finally {
        loading.value = false
      }
    }

    const resetOperations = () => {
      brokers.value = ''
    }

    const updateOperationsWithFilters = () => {
      fetchOperations()
    }

    const resetAndUpdateOperations = () => {
      resetOperations()
      updateOperationsWithFilters()
    }

    onMounted(() => {
      fetchOperations()
    })

    const title = ref('')
    const initialFormData = ref({})
    const modalEnabled = ref(false)
    const editModeEnabled = ref(false)
    const loadingButton = ref(false)

    const handleEdit = (item) => {
      title.value = 'Editar broker'
      initialFormData.value = { ...item }

      editModeEnabled.value = true
      modalEnabled.value = true
    }

    const handleUpdateBroker = async (updatedForm) => {
      loadingButton.value = true

      try {
        await putUpdateBroker(updatedForm.id, updatedForm)
        modalEnabled.value = false
        resetAndUpdateOperations()
      } catch (e) {
        handleGlobalError(e)
      } finally {
        initialFormData.value = {}
        loadingButton.value = false
        editModeEnabled.value = false
        setNoScroll(false)
      }
    }

    const handleFormClose = () => {
      initialFormData.value = {}
      editModeEnabled.value = false
      modalEnabled.value = false
    }

    return {
      tableHeaders,
      brokers,
      loading,

      modalEnabled,
      title,
      handleUpdateBroker,
      handleFormClose,
      initialFormData,
      loadingButton,

      handleEdit
    }
  }
}
</script>

<template>
  <div>
    <form-modal-broker
      v-if="modalEnabled"
      :title="title"
      @onClose="handleFormClose"
      :loading="loadingButton"
      :initialFormData="initialFormData"
      @onSubmitted="handleUpdateBroker"
    />

    <div class="row title">
      <h2 class="col">Brokers</h2>
    </div>

    <div class="row pb-5 mt-3">
      <div class="col-12 form-table">
        <app-table
          v-if="brokers.length > 0"
          :headers="tableHeaders"
          :items="brokers"
          :initialFormData="initialFormData"
          @edit="handleEdit"
        />

        <div v-if="brokers.length === 0 && !loading" class="d-flex align-items-center placeholder">
          <h4 class="col mb-0 text-center">Nada encontrado, tente pesquisar novamente.</h4>
        </div>

        <app-loading v-if="loading" class="pt-5 mt-5 mx-auto" :duration="'2.3s'" />
      </div>
    </div>
  </div>
</template>
