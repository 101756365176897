import { onMounted, onUnmounted } from 'vue'

export const useInfiniteScroll = (callback) => {
  const handleScroll = () => {
    const isScrolledToBottom = () => {
      const scrollY = window.scrollY
      const viewportHeight = window.innerHeight
      const scrollHeight = document.documentElement.scrollHeight

      return scrollY + viewportHeight >= scrollHeight - 100
    }

    if (isScrolledToBottom()) {
      callback()
    }
  }

  onMounted(() => {
    window.addEventListener('scroll', handleScroll)
  })

  onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll)
  })
}
