<script>
import { onMounted, ref, watch } from 'vue'
import { useMeta } from 'vue-meta'

import formDate from '@/components/form/date.vue'
import formInput from '@/components/form/input.vue'
import formSelect from '@/components/form/select.vue'
import appLoading from '@/components/shared/loading.vue'
import appModal from '@/components/shared/modal.vue'
import appTable from '@/components/shared/table.vue'
import { useInfiniteScroll } from '@/composables/utils/useInfiniteScroll'
import { useOperationType } from '@/composables/utils/useOperationType'
import { usePagination } from '@/composables/utils/usePagination'
import { usePermissions } from '@/composables/utils/usePermissions'
import { useSearchThrottle } from '@/composables/utils/useSearch'
import router from '@/router'
import { fetchTargetTax, deleteTargetTax } from '@/services/api'
import { handleGlobalError } from '@/services/errorHandler'

export default {
  name: 'TargetTax',

  components: {
    formInput,
    formSelect,
    formDate,
    appLoading,
    appTable,
    appModal
  },

  props: {
    search: String,
    expiresAt: String,
    type: String,
    status: String
  },

  setup(props) {
    useMeta({ title: 'Taxa Alvo' })

    const modalFormIsShown = ref(false)
    const modalConfig = ref({})

    const { totalPages, currentPage, isLastPage, setPagination, nextPage } = usePagination()

    const searchValue = ref(props.search)
    const expiresAtValue = ref(props.expiresAt)
    const natureTypeValue = ref(props.type)
    const statusValue = ref(props.status ? props.status : 'OPEN')

    const valuesToWatch = [natureTypeValue, expiresAtValue, statusValue]

    const permissions = usePermissions()

    const statusOptions = ref([
      { key: 'OPEN', name: 'ABERTO' },
      { key: 'CLOSED', name: 'FECHADO' },
      { key: 'CANCELED', name: 'CANCELADO' }
    ])

    const operations = ref('')
    const loading = ref(false)

    useSearchThrottle(searchValue, () => {
      resetAndUpdateOperations()
    })

    const { fetchOperationTypeOptions } = useOperationType()

    const operationOptions = fetchOperationTypeOptions()

    const tableHeaders = [
      { key: 'clientName', label: 'Cliente', type: 'text' },
      { key: 'principalAmount', label: 'Valor', type: 'currency', locale: 'pt-BR' },
      { key: 'type', label: 'Tipo', type: 'operationType' },
      {
        key: 'finalQuote',
        label: 'Alvo',
        type: 'quotation',
        locale: 'pt-BR',
        maximumFractionDigits: 4
      },
      { key: 'status', label: 'Status', type: 'statusType' },
      { key: 'expiresAt', label: 'Validade', type: 'date', format: 'dd/MM/yyyy HH:mm' },
      {
        key: 'actions',
        label: 'Ações',
        type: 'actions',
        options: [
          {
            action: 'delete',
            label: 'Deletar',
            class: 'delete',
            emitEvent: 'delete',
            isVisible: permissions.canDeleteData()
          }
        ]
      }
    ]

    const fetchOperations = async (config) => {
      loading.value = true
      try {
        const response = await fetchTargetTax(config)
        operations.value =
          currentPage.value === 0
            ? response.data.payload
            : [...operations.value, ...response.data.payload]

        setPagination(response.data.page.pageCount, currentPage.value)
      } catch (e) {
        handleGlobalError(e)
      } finally {
        loading.value = false
      }
    }

    useInfiniteScroll(() => {
      if (
        !loading.value &&
        operations.value.length !== 0 &&
        currentPage.value < totalPages.value - 1
      ) {
        nextPage()
        updateOperationsWithFilters()
      }
    })

    const resetOperations = () => {
      operations.value = ''
      currentPage.value = 0
    }

    const updateOperationsWithFilters = () => {
      const config = {
        page: currentPage.value,
        search: searchValue.value,
        type: natureTypeValue.value,
        expiresAt: expiresAtValue.value,
        status: statusValue.value
      }

      const queryParams = { ...config }
      delete queryParams.page
      router.push({ name: 'TargetTax', query: queryParams })
      fetchOperations(config)
    }

    const resetAndUpdateOperations = () => {
      resetOperations()
      updateOperationsWithFilters()
    }

    valuesToWatch.forEach((refValue) => {
      watch(refValue, resetAndUpdateOperations)
    })

    onMounted(() => {
      const config = {
        page: 0,
        search: searchValue.value,
        type: natureTypeValue.value,
        expiresAt: expiresAtValue.value,
        status: statusValue.value
      }
      fetchOperations(config)
    })

    const handleDelete = async (item) => {
      modalConfig.value = {
        origin: 'delete-target-tax',
        title: 'Você tem certeza?',
        icon: 'bi bi-exclamation-square-fill',
        description: 'Se você remover esta oferta, ela ficará como taxa alvo para o cliente',
        showButtons: true,
        type: 'warning',
        submitButtonText: 'Confirmar',
        backButtonText: 'Cancelar',
        id: item.id
      }
      modalFormIsShown.value = true
    }

    const loadingModal = ref(false)

    const handleDeleteTargetTax = async (item) => {
      try {
        loadingModal.value = true
        await deleteTargetTax(item.id)
        operations.value = operations.value.filter((op) => op.id !== item.id)
      } catch (e) {
        handleGlobalError(e)
      } finally {
        modalFormIsShown.value = false
        loadingModal.value = false
      }
    }

    const handleConfirm = (item) => {
      if (item.origin === 'delete-target-tax') {
        handleDeleteTargetTax(item)
      }
    }

    return {
      permissions,
      searchValue,
      natureTypeValue,
      expiresAtValue,
      statusValue,
      statusOptions,
      operationOptions,
      modalFormIsShown,
      loadingModal,
      modalConfig,
      handleDelete,
      handleConfirm,
      tableHeaders,
      operations,
      loading,
      isLastPage
    }
  }
}
</script>

<template>
  <div>
    <app-modal
      v-if="modalFormIsShown"
      :modalConfig="modalConfig"
      :loading="loadingModal"
      @submit="handleConfirm"
      @onClose="modalFormIsShown = false"
    />

    <div class="row title">
      <h2 class="col">Taxa Alvo</h2>
    </div>

    <div class="row g-2 mt-3">
      <form-input
        class="col form-input"
        label="Cliente"
        v-model="searchValue"
        id="search"
        name="search"
        type="search"
        icon="bi bi-search"
        placeholder="Nome do cliente"
        autocomplete="off"
      />
      <form-select
        class="col form-input"
        label="Tipo de operação"
        v-model="natureTypeValue"
        :items="operationOptions"
        id="operation"
        name="operation"
      />
      <form-select
        class="col form-input"
        label="Status"
        v-model="statusValue"
        :items="statusOptions"
        id="status"
        name="status"
      />
      <form-date
        class="col form-input"
        label="Expiração"
        v-model="expiresAtValue"
        id="expiresAt"
        name="expiresAt"
        icon="bi bi-calendar-week"
      />
    </div>

    <div class="row pb-5">
      <div class="col-12 form-table">
        <app-table
          v-if="operations.length > 0"
          :headers="tableHeaders"
          :items="operations"
          @delete="handleDelete"
        />

        <div v-if="isLastPage && !loading" class="d-flex align-items-center mt-5">
          <h6 class="col mb-0 text-center">Isso é tudo!</h6>
        </div>

        <div
          v-if="operations.length === 0 && !loading"
          class="d-flex align-items-center placeholder"
        >
          <h4 class="col mb-0 text-center">Nada encontrado, tente pesquisar novamente.</h4>
        </div>

        <app-loading v-if="loading" class="pt-5 mt-5 mx-auto" :duration="'2.3s'" />
      </div>
    </div>
  </div>
</template>
