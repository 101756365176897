import { defineStore } from 'pinia'

export const useCache = defineStore('cache', {
  state() {
    return {
      natureType: [],
      brokers: [],
      countriesBanks: [],
      banks: []
    }
  },

  getters: {
    getNatureType() {
      return this.natureType
    },
    getBrokers() {
      return this.brokers
    },
    getCountriesBanks() {
      return this.countriesBanks
    },
    getBanks() {
      return this.banks
    }
  },
  persist: true
})
