<template>
  <table v-if="items.length > 0">
    <button v-if="showBackToTop" @click="scrollToTop" class="back-to-top">
      <i class="bi bi-caret-up-fill"></i>
    </button>

    <thead class="sticky-top thead-to-top">
      <tr>
        <th v-for="header in headers" :key="header.key">
          {{ header.label }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in items" :key="item.id">
        <td v-for="header in headers" :key="header.key">
          <template v-if="header.type === 'operationType'">
            <span
              v-if="item.targetTaxOrderId && typeof item.targetTaxOrderId === 'string'"
              class="badge bg-danger me-1"
            >
              T.A
            </span>
            <span
              :class="[
                'badge',
                {
                  'bg-sell': item[header.key] === operationTypes.SELLING,
                  'bg-buy': item[header.key] === operationTypes.BUYING
                }
              ]"
            >
              {{ operationType[item[header.key]] }}
            </span>
          </template>

          <template v-else-if="header.type === 'actions'">
            <button
              v-for="option in header.options"
              v-show="option.isVisible !== false"
              @click="$emit(option.emitEvent, item)"
              :key="option.action"
              :style="{ backgroundColor: option.color, color: 'white' }"
              class="action-button"
              :class="option.class"
            >
              {{ option.label }}
            </button>
          </template>

          <template v-else-if="header.type === 'info'">
            <button @click="emitInfo(item)" class="action-button info">
              {{ item[header.key] }}
            </button>
          </template>

          <template v-else-if="header.type === 'statusType'">
            {{ statusType[item[header.key]] }}
          </template>

          <template v-else-if="header.type === 'pendencyType'">
            {{ pendencyType[item[header.key]] }}
          </template>

          <template v-else-if="header.type === 'logsType'">
            {{ logsType[item[header.key]] || item[header.key] }}
          </template>

          <template v-else-if="header.type === 'onboardingStatusType'">
            <span
              class="badge"
              :style="{ 'background-color': onboardingStatusType[item[header.key] || ''].color }"
            >
              {{
                item[header.key] ? onboardingStatusType[item[header.key]].name : emptyPlaceholder
              }}
            </span>
          </template>

          <template v-else-if="header.type === 'userStatusType'">
            <span
              class="badge"
              :style="{ 'background-color': userStatusType[item[header.key] || ''].color }"
            >
              {{ item[header.key] ? userStatusType[item[header.key]].name : emptyPlaceholder }}
            </span>
          </template>

          <template
            v-else-if="
              header.type === 'currency' || header.type === 'spread' || header.type === 'quotation'
            "
          >
            {{ fetchCurrencySymbol(header, item) || '' }}
            {{ item[header.key] ? formatNumber(item[header.key], header) : emptyPlaceholder }}
          </template>

          <template v-else-if="header.type === 'date'">
            {{
              formatDate(item[header.key], header.format, header.timeZoneOffset) || emptyPlaceholder
            }}
          </template>

          <template v-else-if="header.type === 'check'">
            <input
              class="form-check-input ng-untouched ng-pristine ng-valid"
              type="checkbox"
              :checked="item[header.key]"
              disabled
            />
          </template>

          <template v-else>
            <span
              v-if="header.key === 'clientName' && item.institution"
              class="badge"
              :style="{ 'background-color': institutionType[item.institution].color }"
            >
              {{ institutionType[item.institution].tag }}
            </span>
            {{ item[header.key] != null ? truncateText(item[header.key], 35) : emptyPlaceholder }}
            <span
              v-if="header.key === 'clientName' && item.isActive === false"
              class="badge bg-danger"
            >
              <i class="bi bi-ban-fill"></i>
              DESATIVADO
            </span>
          </template>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { parseISO } from 'date-fns'
import { utcToZonedTime, format as formatTz } from 'date-fns-tz'
import { ref, onMounted, onBeforeUnmount } from 'vue'

import { operationTypes, emptyPlaceholder } from '@/composables/utils/useGlobalConfig'
import { useInstitution } from '@/composables/utils/useInstitution'
import { useOnboardingStatus } from '@/composables/utils/useOnboardingStatus'
import { useOperationPendency } from '@/composables/utils/useOperationPendency'
import { useOperationStatus } from '@/composables/utils/useOperationStatus'
import { useOperationType } from '@/composables/utils/useOperationType'
import { usePlatformLogs } from '@/composables/utils/usePlatformLogs'
import { userStatus } from '@/composables/utils/userStatus'

export default {
  name: 'dynamicTable',
  props: {
    headers: {
      type: Array,
      default: () => [],
      required: true
    },
    items: {
      type: Array,
      default: () => [],
      required: true
    }
  },

  setup(props, { emit }) {
    const { statusType } = useOperationStatus()
    const { institutionType } = useInstitution()
    const { operationType } = useOperationType()
    const { pendencyType } = useOperationPendency()
    const { logsType } = usePlatformLogs()
    const { onboardingStatusType } = useOnboardingStatus()
    const { userStatusType } = userStatus()

    const emitInfo = (item) => {
      emit('Info', item)
    }

    const formatDate = (dateString, dateFormat, timeZoneOffset = 0) => {
      if (!dateString) return ''
      const dateWithTime = parseISO(dateString)
      const timeZone = `Etc/GMT${timeZoneOffset > 0 ? '-' : '+'}${Math.abs(timeZoneOffset)}`
      const adjustedDate = utcToZonedTime(dateWithTime, timeZone)
      const formattedDate = formatTz(adjustedDate, dateFormat, { timeZone })
      return formattedDate
    }

    const fetchCurrencySymbol = (header, item) => {
      if (header.type === 'currency') {
        return item.currencyType
      } else if (header.type === 'spread') {
        return item.spreadType == 'PERCENTAGE' ? '%' : 'R$'
      }
    }

    const formatNumber = (value, header) => {
      return value.toLocaleString(header.locale, {
        minimumFractionDigits: header.maximumFractionDigits,
        maximumFractionDigits: header.maximumFractionDigits
      })
    }

    const truncateText = (text, maxLength) => {
      if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...'
      }
      return text
    }

    const showBackToTop = ref(false)

    const handleScroll = () => {
      showBackToTop.value = window.scrollY > 1000
    }

    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    onMounted(() => {
      window.addEventListener('scroll', handleScroll)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('scroll', handleScroll)
    })

    return {
      formatDate,
      fetchCurrencySymbol,
      formatNumber,
      statusType,
      pendencyType,
      logsType,
      institutionType,
      operationType,
      onboardingStatusType,
      userStatusType,
      emitInfo,
      operationTypes,
      showBackToTop,
      scrollToTop,
      emptyPlaceholder,
      truncateText
    }
  }
}
</script>

<style>
.sticky-top {
  z-index: 1;
}

.thead-to-top {
  top: 15px;
}

.back-to-top {
  position: fixed;
  bottom: 12px;
  right: 12px;
  z-index: 1000;
  padding: 11px 15px;
  font-size: 16px;
  border: none;
  border-radius: 9px;
  cursor: pointer;
}

.form-check-input {
  &:checked {
    background-color: var(--secondary-color);
    border-color: var(--secondary-color);
  }

  &[type='checkbox'] {
    border-radius: 0.25em;
    height: 16px;
    width: 16px;
  }
  &:disabled {
    pointer-events: none;
    filter: none;
    opacity: 1;
  }
}
</style>
