<template>
  <div class="docs">
    <div v-for="section in filteredDocs" :key="section.section">
      <app-title :label="section.section" />

      <div class="item" v-for="doc in section.docs" :key="doc.type">
        <div class="row g-1">
          <form-input
            class="col-8 form-input"
            label="Título do documento"
            v-model="doc.title"
            :id="`titleDocument[${doc.type}]`"
            :name="`titleDocument[${doc.type}]`"
            type="text"
            @blur="updateTitle(doc)"
            @keyup.enter="updateTitle(doc)"
          />
          <file-upload
            class="col-4 upload"
            :loading="loading"
            @fileSelected="(file) => handleFileSelected(file, doc)"
          />
          <p class="more col-12">{{ doc.more }}</p>
        </div>

        <div class="row mb-3">
          <div class="col-12 list-file">
            <div class="float-start me-3" v-for="file in doc.files" :key="file.id">
              <i class="bi bi-file-earmark-text-fill me-1"></i>
              <a :href="file.url" target="_blank">
                <span>{{ file.name }}</span>
              </a>
              <i class="bi bi-x-square-fill remove ms-2" @click="removeFile(doc, file)"></i>
            </div>
          </div>
        </div>

        <form-select
          class="col-12 form-input"
          label="Status"
          :modelValue="doc.status"
          :items="statusOptions"
          :id="`status[${doc.type}]`"
          :name="`status[${doc.type}]`"
          @change="updateStatus($event, doc)"
          :isDisabled="isDisabled"
        />

        <form-input
          v-if="doc.status === 'REFUSED'"
          class="col-12 form-input"
          label="Justificativa da recusa"
          v-model="doc.justification"
          :id="`justification[${doc.type}]`"
          :name="`justification[${doc.type}]`"
          type="text"
          @blur="updateJustification(doc)"
          :isDisabled="isDisabled"
        />

        <div class="timeline">
          <div v-for="(log, index) in sortedLogs(doc.logs)" :key="index" class="timeline-item">
            <div class="timeline-content">
              <p class="log-text">{{ log.text }}</p>
              <small class="log-details"> {{ log.user }} - {{ formatDate(log.date) }} </small>
            </div>
          </div>
        </div>

        <form-button
          class="col-12 form-input mt-0"
          buttonColor="fifth mt-0 mb-4"
          title="Remover documento"
          @click="removeDoc(section, doc)"
          :isDisabled="isDisabled"
        />
      </div>

      <div class="row">
        <div class="text-center">
          <img class="line-svg" :src="loadAsset('icon/svg/line_add.svg')" />
        </div>
      </div>

      <form-button
        class="col-12 form-input mt-0"
        buttonColor="secondary mt-1"
        title="Adicionar nova solicitação"
        @click="addDoc(section)"
        :isDisabled="isDisabled"
      />
    </div>
  </div>
</template>

<script>
import { loadAsset } from '@composables/utils/loadAsset.ts'
import { format } from 'date-fns'
import { computed, ref } from 'vue'

import formButton from '@/components/form/button.vue'
import fileUpload from '@/components/form/fileUpload.vue'
import formInput from '@/components/form/input.vue'
import formSelect from '@/components/form/select.vue'
import appTitle from '@/components/shared/title.vue'
import { uploadDocumentOnboarding } from '@/services/api'
import { handleGlobalError } from '@/services/errorHandler'
import { useAuthToken } from '@/stores/authToken.js'

export default {
  name: 'appDocumentManager',

  components: {
    appTitle,
    formInput,
    formButton,
    fileUpload,
    formSelect
  },

  props: {
    clientType: {
      type: String,
      default: ''
    },
    docs: {
      type: Array,
      default: () => []
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },

  setup(props, { emit }) {
    const loading = ref(false)

    const authentication = useAuthToken()

    const statusOptions = ref([
      { key: 'APPROVED', name: 'APROVADO' },
      { key: 'PENDING', name: 'PENDENTE' },
      { key: 'REFUSED', name: 'RECUSADO' }
    ])

    const filteredDocs = computed(() => {
      if (props.docs.length === 0) return []

      const typeKeyMap = {
        PJ: 'ltda',
        PF: 'pf'
      }

      const typeKey = typeKeyMap[props.clientType]
      const clientDocs = props.docs.find((doc) => doc[typeKey])
      return clientDocs ? clientDocs[typeKey] : []
    })

    const sortedLogs = (logs) => {
      return logs.slice().sort((a, b) => new Date(b.date) - new Date(a.date))
    }

    const formatDate = (date) => {
      return format(new Date(date), 'dd/MM/yyyy HH:mm')
    }

    const updateStatus = (event, doc) => {
      const newStatus = event.target.value
      const updatedDocs = JSON.parse(JSON.stringify(props.docs))
      const typeKey = props.clientType === 'PJ' ? 'ltda' : 'pf'

      const clientDocs = updatedDocs.find((docItem) => docItem[typeKey])

      if (clientDocs != null && clientDocs[typeKey]) {
        clientDocs[typeKey].forEach((section) => {
          const targetDoc = section.docs.find((d) => d.type === doc.type)
          if (targetDoc != null) {
            targetDoc.status = newStatus
          }
        })
      }
      emit('docs', updatedDocs)
    }

    const updateTitle = (doc) => {
      const updatedDocs = JSON.parse(JSON.stringify(props.docs))
      const typeKey = props.clientType === 'PJ' ? 'ltda' : 'pf'

      const clientDocs = updatedDocs.find((docItem) => docItem[typeKey])

      if (clientDocs != null && clientDocs[typeKey]) {
        clientDocs[typeKey].forEach((section) => {
          const targetDoc = section.docs.find((d) => d.type === doc.type)
          if (targetDoc != null) {
            targetDoc.title = doc.title
          }
        })
      }
      emit('docs', updatedDocs)
    }

    const removeDoc = (sectionToRemoveFrom, docToRemove) => {
      const updatedDocs = JSON.parse(JSON.stringify(props.docs))
      const typeKey = props.clientType === 'PJ' ? 'ltda' : 'pf'

      const clientDocs = updatedDocs.find((docItem) => docItem[typeKey])

      if (clientDocs != null && clientDocs[typeKey]) {
        const section = clientDocs[typeKey].find(
          (section) => section.section === sectionToRemoveFrom.section
        )

        if (section != null) {
          const docIndex = section.docs.findIndex((d) => d.type === docToRemove.type)
          if (docIndex !== -1) {
            section.docs.splice(docIndex, 1)
          }
        }
      }
      emit('docs', updatedDocs)
    }

    const addDoc = (sectionToAddTo) => {
      const newDoc = {
        files: [],
        logs: [],
        status: 'PENDING',
        type: Date.now().toString(),
        title: 'Novo Documento',
        more: ''
      }
      const updatedDocs = JSON.parse(JSON.stringify(props.docs))
      const typeKey = props.clientType === 'PJ' ? 'ltda' : 'pf'

      const clientDocs = updatedDocs.find((docItem) => docItem[typeKey])

      if (clientDocs != null && clientDocs[typeKey]) {
        const section = clientDocs[typeKey].find(
          (section) => section.section === sectionToAddTo.section
        )

        if (section != null) {
          section.docs.push(newDoc)
        }
      }
      emit('docs', updatedDocs)
    }

    const removeFile = (doc, fileToRemove) => {
      const updatedDocs = JSON.parse(JSON.stringify(props.docs))
      const typeKey = props.clientType === 'PJ' ? 'ltda' : 'pf'

      const clientDocs = updatedDocs.find((docItem) => docItem[typeKey])

      if (clientDocs != null && clientDocs[typeKey]) {
        clientDocs[typeKey].forEach((section) => {
          const targetDoc = section.docs.find((d) => d.type === doc.type)
          if (targetDoc != null) {
            const fileIndex = targetDoc.files.findIndex((f) => f.id === fileToRemove.id)
            if (fileIndex !== -1) {
              targetDoc.files.splice(fileIndex, 1)
            }
          }
        })
      }
      emit('docs', updatedDocs)
    }

    const handleFileSelected = async (file, doc) => {
      const formData = new FormData()
      formData.append('file', file)

      try {
        loading.value = true
        const response = await uploadDocumentOnboarding(formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })

        const newFile = {
          failed: false,
          finishedUpload: true,
          id: Date.now().toString(),
          name: response.data.name,
          url: response.data.payload.url,
          validPreview: false
        }

        const updatedDocs = JSON.parse(JSON.stringify(props.docs))
        const typeKey = props.clientType === 'PJ' ? 'ltda' : 'pf'

        const clientDocs = updatedDocs.find((docItem) => docItem[typeKey])

        if (clientDocs != null && clientDocs[typeKey]) {
          clientDocs[typeKey].forEach((section) => {
            const targetDoc = section.docs.find((d) => d.type === doc.type)
            if (targetDoc != null) {
              targetDoc.files.push(newFile)
            }
          })
        }
        emit('docs', updatedDocs)
      } catch (e) {
        handleGlobalError(e)
      } finally {
        loading.value = false
      }
    }

    const updateJustification = (doc) => {
      const name = authentication.getName || ''
      if (doc.status === 'REFUSED' && doc.justification != null) {
        const newLog = {
          text: doc.justification,
          user: name,
          date: new Date().toISOString()
        }
        doc.logs.push(newLog)
        emit('update:docs', props.docs)
      }
    }

    return {
      statusOptions,
      filteredDocs,
      updateTitle,
      updateStatus,

      removeDoc,
      addDoc,

      removeFile,

      handleFileSelected,
      updateJustification,
      loading,
      loadAsset,

      sortedLogs,
      formatDate
    }
  }
}
</script>

<style lang="scss" scoped>
.docs {
  .item {
    border-bottom: solid 1px var(--fifth-color);
    margin-bottom: 35px;

    .upload {
      margin-top: 0px;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  h6 {
    color: aliceblue;
    font-size: 16px;
  }

  .more {
    font-size: 11px;
  }

  .list-file {
    color: var(--label-color);
    > div {
      border: solid 1px var(--label-color);
      padding: 5px 10px;
      border-radius: 6px;
    }

    span {
      text-decoration: underline;
    }

    .remove {
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }
  }

  .timeline {
    margin-top: 20px;
    margin-bottom: 20px;

    .timeline-item {
      padding-left: 10px;
      border-left: 2px solid var(--fifth-color);

      .timeline-content {
        padding: 0 0 20px;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          left: -16px;
          top: 0;
          width: 10px;
          height: 10px;
          background-color: var(--fifth-color);
          border-radius: 50%;
        }
      }

      .log-text {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 0;
      }

      .log-details {
        font-size: 12px;
        color: var(--label-color);
      }
    }
  }
}
</style>
