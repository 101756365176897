<template>
  <div class="col-12" v-if="contract">
    <div class="status-docusign mb-1">
      <div class="row">
        <div class="col">
          <i class="bi bi-file-earmark-text-fill"></i>
          {{ title }} - {{ contract.metadata?.financialInstitution || '' }}
        </div>
        <div v-if="contract.status === 'COMPLETED'" class="col row g-2 float-end docusign-button">
          <form-button
            class="col-6 form-input"
            title="Contrato"
            :loading="loadingOpenDocusign[contract.id]"
            :isDisabled="loadingOpenDocusign[contract.id]"
            @clicked="openDocusign(contract)"
          />

          <form-button
            class="col-6 form-input"
            title="Certificado"
            :loading="loadingOpenCertificate[contract.id]"
            :isDisabled="loadingOpenCertificate[contract.id]"
            @clicked="openDocusignCertificate(contract)"
          />
        </div>
      </div>
      <div class="row">
        <p>
          {{ contract.signerName }} - {{ contract.signerEmail }}
          <span
            class="float-end text-end"
            :style="{ color: docusignStatus[contract.status].color }"
          >
            {{ docusignStatus[contract.status].name || '' }}
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import formButton from '@/components/form/button.vue'
import { useDocusign } from '@/composables/utils/useDocusign'
import { docusignStatus } from '@/composables/utils/useGlobalConfig'

export default {
  components: {
    formButton
  },
  props: {
    contract: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      default: 'Contrato de câmbio'
    }
  },

  setup() {
    const { openDocusign, loadingOpenDocusign, loadingOpenCertificate, openDocusignCertificate } =
      useDocusign()

    return {
      docusignStatus,

      openDocusign,
      loadingOpenDocusign,
      loadingOpenCertificate,
      openDocusignCertificate
    }
  }
}
</script>

<style lang="scss">
.docusign-button {
  margin-top: 0;

  .form-input {
    margin-top: 0;

    button {
      height: 35px;
      margin-top: 0;

      .goo2 {
        margin-top: -25px;
        transform: scale(0.7);
      }
    }
  }
}
</style>
