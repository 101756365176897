<template>
  <div class="bacen-preview-item">
    <div class="placeholder-results">
      <p
        data-bs-toggle="collapse"
        :data-bs-target="'#collapseBacenPreview'"
        aria-expanded="false"
        aria-controls="collapseBacenPreview"
      >
        Houve <b>{{ items.length }} resultados</b> para essa natureza

        <span class="float-end text-end">
          by
          <logo-guia-cambio />
        </span>
      </p>

      <div class="collapse" :id="'collapseBacenPreview'">
        <div v-for="(item, index) in items" :key="item.id" class="bacen-preview">
          <h3>
            {{ item.title }}
            <a :href="generateUrl(item.id)" target="_blank" class="float-end"> VER MAIS</a>
          </h3>
          <p v-html="truncateDescription(item?.details?.definition?.description)"></p>
          <hr v-if="index < items.length - 1" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { loadAsset } from '@composables/utils/loadAsset.ts'
import { defineComponent } from 'vue'

import logoGuiaCambio from '@/components/shared/_logoGuiaCambio.vue'

export default defineComponent({
  name: 'bacenPreview',
  components: {
    logoGuiaCambio
  },
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  setup() {
    const truncateDescription = (description) => {
      return description.length > 160 ? `${description.substring(0, 160)}...` : description
    }

    const generateUrl = (id) => {
      return `https://app.guiacambio.com.br/details/${id}`
    }

    return {
      truncateDescription,
      generateUrl,
      loadAsset
    }
  }
})
</script>

<style scoped lang="scss">
.bacen-preview-item {
  .placeholder-results {
    color: var(--sixth-color);
    background-color: rgba(225, 225, 225, 0.2);
    border-radius: 9px;
    padding: 15px;
    font-size: 14px;
    line-height: 28px;
    cursor: pointer;

    p {
      color: var(--sixth-color);
      margin-bottom: 0;
    }

    span {
      font-size: 6px;
    }

    .logo {
      max-width: 80%;
    }
  }

  .bacen-preview {
    margin-top: 20px;
    margin-bottom: 10px;

    h3 {
      font-size: 14px;
      color: var(--sixth-color);
    }
    p {
      font-size: 12px;
      line-height: 18px;
      margin-bottom: 0;
    }
    a {
      color: #38e981;
      text-decoration: underline;
      font-size: 11px;
    }

    hr {
      width: 100%;
    }
  }
}
</style>
