import { ref } from 'vue'

export const emptyPlaceholder = ref('-- --')

export const clientTypes = {
  PF: 'PF',
  PJ: 'PJ'
}

export const operationTypes = {
  BUYING: 'BUYING',
  SELLING: 'SELLING'
}

export const transactionTypes = {
  TRANSFERBUY: 'TRANSFERBUY',
  TRADEBUY: 'TRADEBUY',
  TRADESELL: 'TRADESELL',
  TRANSFERSELL: 'TRANSFERSELL'
}

export const liquidationOptions = ref([
  { key: 'D0', name: 'D+0' },
  { key: 'D1', name: 'D+1' },
  { key: 'D2', name: 'D+2' }
])

export const maritalStatusOptions = ref([
  {
    name: 'Casado(a)',
    key: 'MARRIED',
    docusign: 'casado'
  },
  {
    name: 'Solteiro(a)',
    key: 'SINGLE',
    docusign: 'solteiro'
  },
  {
    name: 'Viúvo(a)',
    key: 'WIDOWED',
    docusign: 'viuvo'
  },
  {
    name: 'Divorciado(a)',
    key: 'DIVORCED',
    docusign: 'divorciado'
  },
  {
    name: 'União estável',
    key: 'STABLE_UNION',
    docusign: 'uniao_estavel'
  }
])

export const operationNatureTypes = {
  EXPMERC: 'EXPMERC'
}

export const spreadTypes = {
  PERCENTAGE: 'PERCENTAGE',
  MONETARY: 'MONETARY'
}

export const theme = {
  DARK: 'dark',
  LIGHT: 'light'
}

export const preStatusOptions = ref([
  {
    key: 'ALLOW',
    name: 'Liberado'
  },
  {
    key: 'CANCELED',
    name: 'Cancelado'
  },
  {
    key: 'UNDER_ANALYSIS',
    name: 'Em Análise'
  },
  {
    key: 'REFUSED',
    name: 'Recusado'
  },
  {
    key: 'TED_CLIENT',
    name: 'Esperando TED do Cliente'
  },
  {
    key: 'EDITING',
    name: 'Em Edição'
  },
  {
    key: 'OUTSTANDING_DOCUMENTS',
    name: 'Esperando Documentação'
  },
  {
    key: 'RECEIVED',
    name: 'Recebimento'
  },
  {
    key: 'WAITING_RECEIVEMENT',
    name: 'Aguardando Recebimento do Exterior'
  },
  {
    key: 'WAITING_ONBOARDING',
    name: 'Cadastro em andamento'
  }
])

export const posStatusOptions = ref([
  { key: 'OPEN', name: 'Aberto' },
  { key: 'CLOSE', name: 'Finalizado' }
])

export const docusignStatus = {
  CREATED: { name: '', color: 'yellow' },
  SENT: { name: 'E-mail enviado', color: 'yellow' },
  DELIVERED: { name: 'E-mail recebido', color: 'yellow' },
  COMPLETED: { name: 'Concluído', color: 'green' },
  DECLINED: { name: 'Recusado', color: 'red' },
  VOIDED: { name: 'Anulado', color: 'red' }
}
