<script>
import { onMounted, ref, watch } from 'vue'
import { useMeta } from 'vue-meta'

import formDate from '@/components/form/date.vue'
import formInput from '@/components/form/input.vue'
import formSelect from '@/components/form/select.vue'
import appLoading from '@/components/shared/loading.vue'
import appTable from '@/components/shared/table.vue'
import { useInfiniteScroll } from '@/composables/utils/useInfiniteScroll'
import { usePagination } from '@/composables/utils/usePagination'
import { usePlatformLogs } from '@/composables/utils/usePlatformLogs'
import { useSearchThrottle } from '@/composables/utils/useSearch'
import router from '@/router'
import { fetchPlatformLogs } from '@/services/api'
import { handleGlobalError } from '@/services/errorHandler'

export default {
  name: 'PlatformLogs',

  components: {
    formInput,
    formSelect,
    appLoading,
    appTable,
    formDate
  },

  props: {
    search: String,
    createdAt: String,
    request: String
  },

  setup(props) {
    useMeta({ title: 'PlatformLogs' })

    const { totalPages, currentPage, isLastPage, setPagination, nextPage } = usePagination()
    const { fetchLogsOptions } = usePlatformLogs()
    const logsOptions = fetchLogsOptions()

    const searchValue = ref(props.search)
    const createdValue = ref(props.createdAt)
    const requestValue = ref(props.request)

    const valuesToWatch = [createdValue, requestValue]

    const platformLogs = ref('')
    const loading = ref(false)

    useSearchThrottle(searchValue, () => {
      resetAndUpdatePlatformLogs()
    })

    const tableHeaders = [
      { key: 'clientName', label: 'Empresa', type: 'text' },
      {
        key: 'createdAt',
        label: 'Efetuado em',
        type: 'date',
        format: 'dd/MM/yyyy HH:mm',
        timeZoneOffset: -3
      },
      { key: 'userName', label: 'Usuário', type: 'text' },
      { key: 'request', label: 'Ação', type: 'logsType' }
    ]

    const fetchPlatformLog = async (config) => {
      loading.value = true
      try {
        const response = await fetchPlatformLogs(config)
        platformLogs.value =
          currentPage.value === 0
            ? response.data.payload
            : [...platformLogs.value, ...response.data.payload]

        setPagination(response.data.page.pageCount, currentPage.value)
      } catch (e) {
        handleGlobalError(e)
      } finally {
        loading.value = false
      }
    }

    useInfiniteScroll(() => {
      if (
        !loading.value &&
        platformLogs.value.length !== 0 &&
        currentPage.value < totalPages.value - 1
      ) {
        nextPage()
        updatePlatformLogsWithFilters()
      }
    })

    const resetPlatformLog = () => {
      platformLogs.value = ''
      currentPage.value = 0
    }

    const updatePlatformLogsWithFilters = () => {
      const config = {
        page: currentPage.value,
        search: searchValue.value,
        createdAt: createdValue.value,
        request: requestValue.value
      }

      const queryParams = { ...config }
      delete queryParams.page
      router.push({ name: 'PlatformLogs', query: queryParams })
      fetchPlatformLog(config)
    }

    const resetAndUpdatePlatformLogs = () => {
      resetPlatformLog()
      updatePlatformLogsWithFilters()
    }

    valuesToWatch.forEach((refValue) => {
      watch(refValue, resetAndUpdatePlatformLogs)
    })

    onMounted(() => {
      const config = {
        page: 0,
        search: searchValue.value,
        createdAt: createdValue.value,
        request: requestValue.value
      }
      fetchPlatformLog(config)
    })

    return {
      searchValue,
      createdValue,
      requestValue,
      logsOptions,

      tableHeaders,
      platformLogs,
      loading,

      isLastPage
    }
  }
}
</script>

<template>
  <div>
    <div class="row title">
      <h2 class="col">PlatformLogs</h2>
    </div>

    <div class="row g-2 mt-3">
      <form-input
        class="col form-input"
        label="Cliente"
        v-model="searchValue"
        id="search"
        name="search"
        type="search"
        icon="bi bi-search"
        placeholder="Nome do cliente"
        autocomplete="off"
      />
      <form-date
        class="col form-input"
        label="Data de criação"
        v-model="createdValue"
        id="created"
        name="created"
        icon="bi bi-calendar-week"
      />
      <form-select
        class="col form-input"
        label="Ação"
        v-model="requestValue"
        :items="logsOptions"
        id="request"
        name="request"
      />
    </div>

    <div class="row pb-5">
      <div class="col-12 form-table">
        <app-table v-if="platformLogs.length > 0" :headers="tableHeaders" :items="platformLogs" />

        <div v-if="isLastPage && !loading" class="d-flex align-items-center mt-5">
          <h6 class="col mb-0 text-center">Isso é tudo!</h6>
        </div>

        <div
          v-if="platformLogs.length === 0 && !loading"
          class="d-flex align-items-center placeholder"
        >
          <h4 class="col mb-0 text-center">Nada encontrado, tente pesquisar novamente.</h4>
        </div>

        <app-loading v-if="loading" class="pt-5 mt-5 mx-auto" :duration="'2.3s'" />
      </div>
    </div>
  </div>
</template>
