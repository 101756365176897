<script>
import { onMounted, ref } from 'vue'
import { useMeta } from 'vue-meta'
import * as yup from 'yup'

import formModal from '@/components/shared/formModal.vue'
import appLoading from '@/components/shared/loading.vue'
import appModal from '@/components/shared/modal.vue'
import appTable from '@/components/shared/table.vue'
import { useInfiniteScroll } from '@/composables/utils/useInfiniteScroll'
import { useNoScroll } from '@/composables/utils/useNoScroll'
import { usePagination } from '@/composables/utils/usePagination'
import { useS3Uploader } from '@/composables/utils/useS3Uploader'
import { handleGlobalError } from '@/services/errorHandler'
import { fetchNews, createNews, putUpdateNews, deleteNews, uploadNewsletters } from '@/services/ms'

export default {
  name: 'appNewsletter',

  components: {
    appLoading,
    appTable,
    formModal,
    appModal
  },

  setup() {
    useMeta({ title: 'Notícias' })

    const { totalPages, currentPage, isLastPage, setPagination, nextPage } = usePagination()

    const size = ref(20)

    const modalFormIsShown = ref(false)
    const modalConfig = ref({})

    const operations = ref('')
    const loading = ref(false)

    const { setNoScroll } = useNoScroll()

    const tableHeaders = [
      { key: 'title', label: 'Título', type: 'text' },
      { key: 'source', label: 'Fonte', type: 'text' },
      {
        key: 'actions',
        label: 'Ações',
        type: 'actions',
        options: [
          { action: 'edit', label: 'Editar', class: 'edit', emitEvent: 'edit' },
          { action: 'delete', label: 'Deletar', class: 'delete', emitEvent: 'delete' }
        ]
      }
    ]

    const fetchOperations = async (config) => {
      loading.value = true
      try {
        const response = await fetchNews(config)
        operations.value =
          currentPage.value === 0
            ? response.data.payload
            : [...operations.value, ...response.data.payload]

        setPagination(response.data.page.pageCount, currentPage.value)
      } catch (e) {
        handleGlobalError(e)
      } finally {
        loading.value = false
      }
    }

    useInfiniteScroll(() => {
      if (
        !loading.value &&
        operations.value.length !== 0 &&
        currentPage.value < totalPages.value - 1
      ) {
        nextPage()
        const config = {
          page: currentPage.value
        }
        fetchOperations(config)
      }
    })

    const resetOperations = () => {
      operations.value = ''
      currentPage.value = 0
    }

    const updateOperationsWithFilters = () => {
      const config = {
        page: currentPage.value,
        size: size.value
      }

      fetchOperations(config)
    }

    const resetAndUpdateOperations = () => {
      resetOperations()
      updateOperationsWithFilters()
    }

    onMounted(() => {
      const config = {
        page: 0,
        size: size.value
      }
      fetchOperations(config)
    })

    const title = ref('')
    const initialFormData = ref({})
    const modalEnabled = ref(false)
    const editModeEnabled = ref(false)
    const loadingButton = ref(false)

    const formSchema = [
      {
        component: 'formInput',
        name: 'title',
        label: 'Título',
        type: 'text',
        class: 'col-12 form-input',
        value: 'title',
        rules: yup.string().required()
      },
      {
        component: 'formInput',
        name: 'source',
        label: 'Fonte',
        type: 'text',
        class: 'col-12 form-input',
        value: 'source',
        rules: yup.string().required()
      },
      {
        component: 'formInput',
        name: 'link',
        label: 'Link',
        type: 'text',
        class: 'col-12 form-input',
        value: 'link'
      },
      {
        component: 'formTextarea',
        name: 'summary',
        label: 'Notícia',
        class: 'col-12 form-input',
        value: 'summary',
        rules: yup.string().required()
      },
      {
        component: 'appImportDocument',
        name: 'importdocument',
        label: 'importdocument',
        class: 'col-12 form-input',
        value: 'importdocument'
      }
    ]
    const newNewsletter = () => {
      title.value = 'Adicionar notícia'
      modalEnabled.value = true
    }

    const handleFormCreation = async (formData) => {
      if (!editModeEnabled.value) {
        handleSubmitNews(formData)
      } else {
        handleUpdateNews(formData)
      }
    }

    const { uploadFileToS3 } = useS3Uploader()

    const handleSubmitNews = async (formData) => {
      loadingButton.value = true

      let photoUrl = null

      if (formData.importDocument != null) {
        try {
          photoUrl = await uploadFileToS3(formData.importDocument, uploadNewsletters)
        } catch (e) {
          handleGlobalError(e)
        }
      }

      try {
        const payload = {
          title: formData.title,
          link: formData.link,
          summary: formData.summary,
          source: formData.source,
          photoUrl: formData.importDocument ? photoUrl : null
        }

        await createNews(payload)
        modalEnabled.value = false
        resetAndUpdateOperations()
      } catch (e) {
        handleGlobalError(e)
      } finally {
        initialFormData.value = {}
        loadingButton.value = false
        setNoScroll(false)
      }
    }

    const handleEdit = (item) => {
      title.value = 'Editar notícia'
      initialFormData.value = { ...item }

      editModeEnabled.value = true
      modalEnabled.value = true
    }

    const handleUpdateNews = async (updatedForm) => {
      loadingButton.value = true

      try {
        const payload = {
          title: updatedForm.title,
          link: updatedForm.link,
          summary: updatedForm.summary,
          source: updatedForm.source
        }

        await putUpdateNews(updatedForm.id, payload)
        modalEnabled.value = false
        resetAndUpdateOperations()
      } catch (e) {
        handleGlobalError(e)
      } finally {
        initialFormData.value = {}
        loadingButton.value = false
        editModeEnabled.value = false
        setNoScroll(false)
      }
    }

    const handleDelete = async (item) => {
      modalConfig.value = {
        origin: 'delete-news',
        title: 'Você tem certeza?',
        icon: 'bi bi-exclamation-square-fill',
        description: 'Deseja mesmo apagar esta notícia, ação não poderá ser revertida.',
        showButtons: true,
        type: 'warning',
        submitButtonText: 'Confirmar',
        backButtonText: 'Cancelar',
        id: item.id
      }
      modalFormIsShown.value = true
    }

    const loadingModal = ref(false)

    const handleDeleteNews = async (item) => {
      try {
        loadingModal.value = true
        await deleteNews(item.id)
        operations.value = operations.value.filter((op) => op.id !== item.id)
      } catch (e) {
        handleGlobalError(e)
      } finally {
        modalFormIsShown.value = false
        loadingModal.value = false
        setNoScroll(false)
      }
    }

    const handleConfirm = (item) => {
      if (item.origin === 'delete-news') {
        handleDeleteNews(item)
      }
    }

    const handleFormClose = () => {
      initialFormData.value = {}
      editModeEnabled.value = false
      modalEnabled.value = false
    }

    return {
      tableHeaders,
      operations,
      loading,

      isLastPage,

      newNewsletter,
      modalFormIsShown,
      modalEnabled,
      modalConfig,
      title,
      formSchema,
      handleSubmitNews,
      handleUpdateNews,
      handleFormClose,
      initialFormData,
      loadingButton,

      handleEdit,
      handleDelete,

      handleFormCreation,
      handleConfirm,
      loadingModal
    }
  }
}
</script>

<template>
  <div>
    <form-modal
      v-if="modalEnabled"
      @onClose="handleFormClose"
      :title="title"
      :formSchema="formSchema"
      :loading="loadingButton"
      :initialFormData="initialFormData"
      @onSubmitted="handleFormCreation"
    />

    <app-modal
      v-if="modalFormIsShown"
      :modalConfig="modalConfig"
      :loading="loadingModal"
      @submit="handleConfirm"
      @onClose="modalFormIsShown = false"
    />

    <div class="row title">
      <h2 class="col">Notícias</h2>
      <div class="col-12 col-md-3 form-input">
        <button class="secondary" @click="newNewsletter()">
          <i class="bi bi-node-plus-fill"></i>
          Nova Notícia
        </button>
      </div>
    </div>

    <div class="row pb-5 mt-3">
      <div class="col-12 form-table">
        <app-table
          v-if="operations.length > 0"
          :headers="tableHeaders"
          :items="operations"
          :initialFormData="initialFormData"
          @edit="handleEdit"
          @delete="handleDelete"
        />

        <div v-if="isLastPage && !loading" class="d-flex align-items-center mt-5">
          <h6 class="col mb-0 text-center">Isso é tudo!</h6>
        </div>

        <div
          v-if="operations.length === 0 && !loading"
          class="d-flex align-items-center placeholder"
        >
          <h4 class="col mb-0 text-center">Nada encontrado, tente pesquisar novamente.</h4>
        </div>

        <app-loading v-if="loading" class="pt-5 mt-5 mx-auto" :duration="'2.3s'" />
      </div>
    </div>
  </div>
</template>
