import DesignSystem from '@design_system/index.vue'

import Layout from '@/container/Layout.vue'
import Main from '@/container/Main.vue'
import Clients from '@/views/Clients.vue'
import CompletedOperations from '@/views/CompletedOperations.vue'
import Dashboard from '@/views/Dashboard.vue'
import Holidays from '@/views/Holidays.vue'
import PosOperations from '@/views/PosOperations.vue'
import PreOperations from '@/views/PreOperations.vue'
import TargetTaxes from '@/views/TargetTaxes.vue'
import Login from '@/views/auth/Login.vue'
import Recover from '@/views/auth/password/Recover.vue'
import ResetPassword from '@/views/auth/password/ResetPassword.vue'
import BankerPromoCode from '@/views/super-admins/BankerPromoCode.vue'
import Brokers from '@/views/super-admins/Brokers.vue'
import GiftCard from '@/views/super-admins/GiftCard.vue'
import Newsletter from '@/views/super-admins/Newsletter.vue'
import Parameters from '@/views/super-admins/Parameters.vue'
import PlatformAlert from '@/views/super-admins/PlatformAlert.vue'
import PlatformLogs from '@/views/super-admins/PlatformLogs.vue'
import PromoCodes from '@/views/super-admins/PromoCodes.vue'
import PushNotification from '@/views/super-admins/PushNotification.vue'
import Users from '@/views/super-admins/Users.vue'

export default [
  {
    path: '/',
    redirect: '/dashboard'
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/'
  },
  {
    path: '/login',
    component: Login,
    name: 'Login',
    meta: { requiresAuth: false }
  },
  {
    path: '/login/recover',
    component: Recover,
    name: 'Recover',
    meta: { requiresAuth: false }
  },
  {
    path: '/login/reset-password',
    component: ResetPassword,
    name: 'ResetPassword',
    props: (route) => ({
      user: route.query.user,
      code: route.query.code
    }),
    meta: { requiresAuth: false }
  },
  {
    path: '/',
    name: 'Main',
    redirect: 'dashboard',
    component: Main,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'dashboard',
        component: Dashboard,
        name: 'Dashboard'
      },
      {
        path: 'target-taxes',
        component: TargetTaxes,
        name: 'TargetTaxes',
        props: (route) => ({
          search: route.query.search,
          type: route.query.type,
          expiresAt: route.query.expiresAt,
          status: route.query.status
        })
      },
      {
        path: 'pre-operations',
        component: PreOperations,
        name: 'PreOperations',
        props: (route) => ({
          search: route.query.search,
          status: route.query.status,
          type: route.query.type,
          createdAt: route.query.createdAt,
          id: route.query.id
        })
      },
      {
        path: 'pos-operations',
        component: PosOperations,
        name: 'PosOperations',
        props: (route) => ({
          search: route.query.search,
          status: route.query.status,
          type: route.query.type,
          closedAt: route.query.closedAt,
          id: route.query.id
        })
      },
      {
        path: 'completed-operations',
        component: CompletedOperations,
        name: 'CompletedOperations',
        props: (route) => ({
          search: route.query.search,
          type: route.query.type,
          closedAt: route.query.closedAt,
          id: route.query.id
        })
      },
      {
        path: 'clients',
        component: Clients,
        name: 'Clients',
        props: (route) => ({
          search: route.query.search,
          clientId: route.query.clientId,
          onboardingStatus: route.query.onboardingStatus,
          onboardedAt: route.query.onboardedAt,
          orderBy: route.query.orderBy,
          id: route.query.id,
          idOnboarding: route.query.idOnboarding,
          lastClosedOperationNewerThan: route.query.lastClosedOperationNewerThan,
          institution: route.query.institution,
          clientType: route.query.clientType
        })
      },

      {
        path: 'super-admins',
        component: Layout,

        children: [
          {
            path: 'holidays',
            component: Holidays,
            name: 'Holidays'
          },
          {
            path: 'push-notification',
            component: PushNotification,
            name: 'PushNotification'
          },
          {
            path: 'newsletter',
            component: Newsletter,
            name: 'Newsletter'
          },
          {
            path: 'promo-codes',
            component: PromoCodes,
            name: 'PromoCodes'
          },
          {
            path: 'parameters',
            component: Parameters,
            name: 'Parameters'
          },
          {
            path: 'gift-card',
            component: GiftCard,
            name: 'GiftCard'
          },
          {
            path: 'platform-alert',
            component: PlatformAlert,
            name: 'PlatformAlert'
          },
          {
            path: 'platform-logs',
            component: PlatformLogs,
            name: 'PlatformLogs',
            props: (route) => ({
              search: route.query.company,
              createdAt: route.query.createdAt,
              request: route.query.request
            })
          },
          {
            path: 'users',
            component: Users,
            name: 'Users',
            props: (route) => ({
              search: route.query.search
            })
          },
          {
            path: 'brokers',
            component: Brokers,
            name: 'brokers'
          }
        ]
      },
      {
        path: 'banker-promo-code',
        component: BankerPromoCode,
        name: 'BankerPromoCode'
      }
    ]
  },
  {
    path: '/design-system',
    component: DesignSystem,
    name: 'DesignSystem',
    meta: { requiresAuth: true }
  }
]
