<script>
import { loadAsset } from '@composables/utils/loadAsset.ts'
import { format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { ref, onMounted, watch, toRefs, computed } from 'vue'

import toggleSwitchMenu from '@/components/_toggleSwitchMenu.vue'
import { theme } from '@/composables/utils/useGlobalConfig'
import { useNumberFormatter } from '@/composables/utils/useNumberFormatter'
import { useOperationMonitor } from '@/composables/utils/useOperationMonitor'
import router from '@/router'
import {
  fetchMarketStatus,
  fetchPTAX,
  fetchNatureType,
  fetchBrokers,
  fetchCountriesBanks,
  fetchBanks
} from '@/services/api'
import { handleGlobalError } from '@/services/errorHandler'
import { fetchExchangeRate } from '@/services/ms'
import { useWebSocket } from '@/services/useWebSocket'
import { useAuthToken } from '@/stores/authToken.js'
import { useCache } from '@/stores/cache.js'
import { useConfig } from '@/stores/config.js'
import { useWss } from '@/stores/wss.js'

export default {
  name: 'appStatusBar',

  components: {
    toggleSwitchMenu
  },

  setup() {
    const authentication = useAuthToken()
    useOperationMonitor()

    const { startWebSocketConnections, disconnectWebSocketConnections } = useWebSocket()
    const { getExchangeRate, isMarketClosed, getPTAX } = toRefs(useWss())

    const isStatusMarketClosed = ref(isMarketClosed.value)
    const variationInfo = ref('')
    const isPositive = ref(false)
    const variationNow = ref('')
    const dateInfo = ref('--:--:--')

    const { numberFormat } = useNumberFormatter()

    const formatNumber = (number) => {
      const decimalNumber = number / 100
      return numberFormat(decimalNumber, {
        style: 'percent',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    }

    const actionLogout = async () => {
      await disconnectWebSocketConnections()
      authentication.resetState()
      setTimeout(() => {
        router.push('/login')
      }, 300)
    }

    const loadMarketStatus = async () => {
      try {
        const response = await fetchMarketStatus()
        isStatusMarketClosed.value = response.data.payload
        useWss().market = response.data.payload
      } catch (e) {
        handleGlobalError(e, 'fetchMarketStatus')
      }
    }

    watch(isMarketClosed, (newVal) => {
      isStatusMarketClosed.value = newVal
    })

    const loadCurrencyInfo = async () => {
      try {
        const response = await fetchExchangeRate(config.favoriteCurrency)
        formatValueBuy(response.data.payload.buyPrice)
        formatValueSell(response.data.payload.sellPrice)
        variationInfo.value = response.data.payload.openChange
        variationNow.value = response.data.payload.currentPrice
        dateInfo.value = format(new Date(response.data.payload.lastRefreshed), 'HH:mm:ss')
        isPositive.value = variationInfo.value >= 0
      } catch (e) {
        handleGlobalError(e, 'fetchExchangeRate')
      }
    }

    watch(
      getExchangeRate,
      (newVal) => {
        if (newVal != null && newVal[config.favoriteCurrency] != null) {
          formatCurrencyType(newVal[config.favoriteCurrency])
        }
      },
      { deep: true }
    )

    const formatCurrencyType = (value) => {
      const splits = value.split('|')
      formatValueBuy(splits[7])
      formatValueSell(splits[6])
      variationInfo.value = splits[8]
      variationNow.value = splits[4]
      dateInfo.value = format(new Date(splits[5]), 'HH:mm:ss')
      isPositive.value = variationInfo.value >= 0
    }

    const intBuy = ref('0')
    const decimalBuy = ref('0000')

    const formatValueBuy = (value) => {
      const valStr = parseFloat(value.toString()).toFixed(4)
      const valArray = valStr.split('.')
      intBuy.value = valArray[0]
      decimalBuy.value = valArray[1].slice(0, 4)
    }

    const intSell = ref('0')
    const decimalSell = ref('0000')

    const formatValueSell = (value) => {
      const valStr = parseFloat(value.toString()).toFixed(4)
      const valArray = valStr.split('.')
      intSell.value = valArray[0]
      decimalSell.value = valArray[1].slice(0, 4)
    }

    const loadPTAXInfo = async () => {
      try {
        const response = await fetchPTAX(config.favoriteCurrency)
        formatValuePTAX(response.data.payload)
        useWss().PTAX = response.data.payload
      } catch (e) {
        handleGlobalError(e, 'fetchPtax')
      }
    }

    const ptax = ref({})

    const formatValuePTAX = (value) => {
      for (const key of ['ptax10', 'ptax11', 'ptax12', 'ptax13', 'ptax']) {
        if (value[key] == null) continue
        ptax.value[key] = Number(value[key])
      }

      const defaultDate = new Date()
      const createdAt = value.createdAt ? new Date(`${value.createdAt}T00:00:00Z`) : defaultDate
      ptax.value.date = format(utcToZonedTime(createdAt, 'UTC'), 'dd/MM')
    }

    watch(getPTAX, (newVal) => {
      if (newVal.currency !== config.favoriteCurrency) return
      formatValuePTAX(newVal)
    })

    const formatPTAXValue = (value) => {
      return value?.toFixed(4).toString().replace('.', ',') || '0,0000'
    }

    const ptaxEntries = computed(() => {
      const entries = [
        { label: '10h', key: 'ptax10' },
        { label: '11h', key: 'ptax11' },
        { label: '12h', key: 'ptax12' },
        { label: '13h', key: 'ptax13' }
      ].map((entry) => ({
        ...entry,
        active: ptax.value[entry.key] !== undefined && ptax.value[entry.key] !== 0,
        value: formatPTAXValue(ptax.value[entry.key]),
        showDate: false
      }))

      entries.unshift({
        label: `PTAX ${ptax.value.date || '00/00'}`,
        key: 'ptax',
        active: ptax.value.ptax !== undefined && ptax.value.ptax !== 0,
        value: formatPTAXValue(ptax.value.ptax),
        showDate: true
      })

      return entries
    })

    const loadNatures = async () => {
      try {
        const config = {
          shouldIncludeDisabled: true
        }
        const response = await fetchNatureType(config)
        useCache().natureType = response.data.payload
      } catch (e) {
        handleGlobalError(e, 'fetchNatureType')
      }
    }

    const loadBrokers = async () => {
      try {
        const response = await fetchBrokers()
        useCache().brokers = response.data.payload
      } catch (e) {
        handleGlobalError(e, 'fetchBrokers')
      }
    }

    const loadCountriesBanks = async () => {
      try {
        const response = await fetchCountriesBanks()
        useCache().countriesBanks = response.data.payload
      } catch (e) {
        handleGlobalError(e, 'fetchCountriesBanks')
      }
    }

    const loadBanks = async () => {
      try {
        const response = await fetchBanks()
        useCache().banks = response.data.payload
      } catch (e) {
        handleGlobalError(e, 'fetchBanks')
      }
    }

    // TODO: Temporario para limpa exchangeRate que não for Object
    const checkExchangeRate = () => {
      const exchangeRate = useWss().exchangeRate
      if (typeof exchangeRate === 'string') {
        useWss().exchangeRate = {}
      }
    }

    onMounted(async () => {
      // TODO: Temporario para limpa exchangeRate que não for Object
      checkExchangeRate()

      if (getExchangeRate.value[config.favoriteCurrency] != null) {
        formatCurrencyType(getExchangeRate.value[config.favoriteCurrency])
      }
      if (getPTAX.value != null) {
        formatValuePTAX(getPTAX.value)
      }
      loadMarketStatus()
      loadNatures()
      loadBrokers()
      loadCountriesBanks()
      loadBanks()
      selectFavoriteCurrency(config.favoriteCurrency)
      await startWebSocketConnections()
    })

    const config = useConfig()

    const toggleDarkMode = () => {
      const htmlElement = document.documentElement
      const isDark = config.theme === theme.DARK

      htmlElement.classList.remove(isDark ? theme.DARK : theme.LIGHT)
      config.theme = isDark ? theme.LIGHT : theme.DARK
      htmlElement.classList.add(config.theme)
    }

    const toggleNotificationSound = () => {
      config.sound = !config.sound
    }

    const firstName = computed(() => {
      const name = authentication.getName
      return name ? name.split(' ')[0] : ''
    })

    const initials = computed(() => {
      const name = authentication.getName || ''
      const words = name.split(' ')
      const initials = words
        .filter((word) => word.length > 0)
        .slice(0, 2)
        .map((word) => word[0])
        .join('')

      return initials
    })

    const currencyNames = {
      USD: 'DÓLAR',
      GBP: 'LIBRA',
      EUR: 'EURO',
      CAD: 'DÓLAR CANADENSE',
      CHF: 'FRANCO SUÍÇO',
      JPY: 'IENE',
      AUD: 'DÓLAR AUSTRALIANO'
    }

    const currencies = Object.keys(currencyNames)

    const selectFavoriteCurrency = (coin) => {
      config.favoriteCurrency = coin
      loadCurrencyInfo()
      loadPTAXInfo()
    }

    return {
      actionLogout,
      toggleDarkMode,
      toggleNotificationSound,
      useConfig,
      isStatusMarketClosed,
      formatNumber,

      intBuy,
      decimalBuy,

      intSell,
      decimalSell,

      variationInfo,
      isPositive,
      dateInfo,

      ptaxEntries,
      loadAsset,

      initials,
      firstName,

      selectFavoriteCurrency,
      currencyNames,
      currencies,
      config
    }
  }
}
</script>

<template>
  <div class="row">
    <div class="col nav-bar-operation">
      <div class="d-flex align-items-center">
        <div class="flag">
          <img
            :src="loadAsset(`coins/${config.favoriteCurrency}.svg`)"
            class="img-responsive rounded"
            height="36"
            width="53"
            alt="flag"
            id="navbarDropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          />
          <p>{{ currencyNames[config.favoriteCurrency] }} <br />COMERCIAL</p>
          <ul class="dropdown-menu select-favorite-currency" aria-labelledby="navbarDropdown">
            <div v-for="currency in currencies" :key="currency">
              <li
                v-if="config.favoriteCurrency !== currency"
                @click="selectFavoriteCurrency(currency)"
              >
                <img
                  :src="loadAsset(`coins/${currency}.svg`)"
                  class="img-responsive rounded"
                  height="36"
                  width="53"
                  alt="flag"
                />
              </li>
            </div>
          </ul>
        </div>
        <div class="brokerage">
          <p>
            COMPRA <br />
            <span>{{ intBuy }},{{ decimalBuy }}</span>
          </p>
          <p>
            VENDA <br />
            <span>{{ intSell }},{{ decimalSell }}</span>
          </p>
          <p>
            VARIAÇÃO <br />
            <span
              :class="{
                bull_market: isPositive,
                bear_market: !isPositive
              }"
            >
              {{ formatNumber(variationInfo) }}
              <i
                class="bi"
                :class="{
                  'bi-caret-up-fill': isPositive,
                  'bi-caret-down-fill': !isPositive
                }"
              ></i>
            </span>
          </p>
          <p>
            ATUALIZADO <br />
            <span>{{ dateInfo }}</span>
          </p>
        </div>
        <div class="ptax-box">
          <p v-for="entry in ptaxEntries" :key="entry.key" :class="{ active: entry.active }">
            {{ entry.label }} <br />
            <span>{{ entry.value }}</span>
          </p>
        </div>
        <div class="status">
          <p>
            STATUS:
            <span
              class="market"
              :class="{
                open_market: isStatusMarketClosed,
                close_market: !isStatusMarketClosed
              }"
            >
              {{ isStatusMarketClosed ? 'ABERTO' : 'FECHADO' }} </span
            ><br />
            <span>09h10 às 16h00</span>
          </p>
        </div>

        <li class="dropdown ms-auto">
          <a
            class="dropdown-toggle"
            href="#"
            id="navbarDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <div>
              {{ initials }}
            </div>
            {{ firstName }}
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li class="radio-dropdown-menu">
              <toggle-switch-menu
                id="sound"
                name="sound"
                :isChecked="useConfig().sound"
                label="Sons"
                :onToggle="toggleNotificationSound"
              />
            </li>

            <li class="radio-dropdown-menu">
              <toggle-switch-menu
                id="theme"
                name="theme"
                :isChecked="useConfig().theme === 'dark'"
                label="Dark Mode"
                :onToggle="toggleDarkMode"
              />
            </li>

            <li class="radio-dropdown-menu" @click="actionLogout">
              <i class="bi bi-box-arrow-right"></i>
              Deslogar
            </li>
          </ul>
        </li>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.nav-bar-operation {
  > div {
    background-color: #131517;
    padding: 9px 22px;
    border-radius: 11px;
    margin-top: 24px;

    .flag {
      cursor: pointer;

      > img {
        float: left;
      }

      p {
        float: left;
        line-height: 17px;
        margin-bottom: 0;
        padding-left: 10px;
        font-size: 12px;
        font-weight: 500;
        color: #b7b7b7;
      }

      .select-favorite-currency {
        background-color: #131517;
        margin-left: -8px !important;
        min-width: 10px;
        padding: 0;

        li {
          padding: 8px;

          img {
            transition: all 300ms linear;
          }

          &:hover {
            background-color: transparent;

            img {
              transform: scale(1.05);
            }
          }
        }
      }
    }

    .brokerage {
      p {
        float: left;
        line-height: 17px;
        margin-bottom: 0;
        padding-left: 18px;
        font-size: 12px;
        font-weight: 500;
        color: #b7b7b7;

        span {
          color: #ffffff;

          &.bull_market {
            color: #0bdd78;
          }

          &.bear_market {
            color: #dd0b0b;
          }
        }
      }
    }

    .ptax-box {
      background-color: #2c272e;
      border-radius: 11px;
      padding: 15px 30px;
      margin: 0 30px;

      p {
        float: left;
        line-height: 17px;
        margin-bottom: 0;
        padding-left: 18px;
        font-size: 12px;
        font-weight: 500;
        color: #b7b7b7;

        &:first-child {
          padding-left: 0;
        }

        span {
          color: #484646;
        }

        &.active {
          span {
            color: #ffffff;
          }
        }
      }
    }

    .status {
      p {
        float: left;
        line-height: 17px;
        margin-bottom: 0;
        font-size: 12px;
        font-weight: 500;
        color: #b7b7b7;

        span {
          color: #ffffff;

          &.market {
            border-radius: 5px;
            padding: 0 5px;
            color: #000000;
            background-color: #757575;
          }

          &.open_market {
            border-radius: 5px;
            padding: 0 5px;
            color: #000000;
            background-color: #0bdd78;
          }

          &.close_market {
            border-radius: 5px;
            padding: 0 5px;
            color: #ffffff;
            background-color: #dd0b0b;
          }
        }
      }
    }

    .dropdown {
      .dropdown-toggle {
        padding: 10px 0px;
        color: #ffffff;
        img {
          margin-right: 14px;
        }
      }
    }
  }

  .brokerage {
    p {
      float: left;
      line-height: 17px;
      margin-bottom: 0;
      padding-left: 18px;
      font-size: 12px;
      font-weight: 500;
      color: #b7b7b7;

      span {
        color: #ffffff;

        &.bull_market {
          color: #0bdd78;
        }

        &.bear_market {
          color: #dd0b0b;
        }
      }
    }
  }

  .ptax-box {
    background-color: #2c272e;
    border-radius: 11px;
    padding: 15px 30px;
    margin: 0 30px;

    p {
      float: left;
      line-height: 17px;
      margin-bottom: 0;
      padding-left: 18px;
      font-size: 12px;
      font-weight: 500;
      color: #b7b7b7;

      &:first-child {
        padding-left: 0;
      }

      span {
        color: #ffffff;
      }
    }
  }

  .status {
    p {
      float: left;
      line-height: 17px;
      margin-bottom: 0;
      font-size: 12px;
      font-weight: 500;
      color: #b7b7b7;

      span {
        color: #ffffff;

        &.open_market {
          border-radius: 5px;
          padding: 0 5px;
          color: #000000;
          background-color: #0bdd78;
        }

        &.close_market {
          border-radius: 5px;
          padding: 0 5px;
          color: #ffffff;
          background-color: #dd0b0b;
        }
      }
    }
  }

  .dropdown {
    .dropdown-toggle {
      font-size: 14px;
      color: #ffffff;
      div {
        margin-right: 4px;
        width: 28px;
        height: 28px;
        background-color: #2e3034;
        color: #ffffff;
        padding-top: 6px;
        padding-left: 1px;
        border-radius: 20px;
        text-align: center;
        display: inline-grid;
        font-size: 12px;
      }
      img {
        margin-right: 14px;
      }
    }
  }

  .dropdown-menu {
    font-size: 14px;

    .dropdown-item {
      padding: 8px 15px;

      &:hover {
        color: #000 !important;
        background-color: #ffffff;
      }

      &:focus {
        color: #000 !important;
        background-color: #ffffff;
      }
    }

    .radio-dropdown-menu {
      padding: 8px 15px;
      cursor: pointer;
    }

    li {
      &:hover {
        background-color: #eeeeee;
      }
    }
  }
}
</style>
