<script>
import { loadAssetWhiteLabel } from '@composables/utils/loadAsset.ts'
import { ref } from 'vue'

import MenuItem from './MenuItem.vue'

import appImg from '@/components/shared/img.vue'
import { usePermissions } from '@/composables/utils/usePermissions'

export default {
  name: 'appMenu',

  components: {
    appImg,
    MenuItem
  },

  setup() {
    const permissions = usePermissions()

    const menuItems = ref([
      {
        label: 'OPERAÇÃO DE CÂMBIO',
        submenu: [
          {
            label: 'Dashboard',
            path: '/dashboard',
            icon: 'bi bi-speedometer',
            isVisible: permissions.canViewDashboard()
          },
          {
            label: 'Taxa alvo',
            path: '/target-taxes',
            icon: 'bi bi-bullseye',
            isVisible: permissions.canViewTargetTaxes()
          },
          {
            label: 'Pré-operação',
            path: '/pre-operations',
            icon: 'bi bi-wallet-fill',
            isVisible: permissions.canViewPreOperations()
          },
          {
            label: 'Pós-operação',
            path: '/pos-operations',
            icon: 'bi bi-clipboard2-check-fill',
            isVisible: permissions.canViewPostOperations()
          },
          {
            label: 'Liquídadas',
            path: '/completed-operations',
            icon: 'bi bi-calendar2-range-fill',
            isVisible: permissions.canViewCompletedOperations()
          }
        ]
      },
      {
        label: 'ADMINISTRAÇÃO',
        submenu: [
          {
            label: 'Clientes',
            path: '/clients',
            icon: 'bi bi-person-square',
            isVisible: permissions.canViewClients()
          },
          {
            label: 'Avançado',
            path: '',
            icon: 'bi bi-pc-display',
            isAccordion: true,
            submenu: [
              {
                label: 'Feriados',
                path: '/super-admins/holidays',
                icon: 'bi bi-calendar2-week-fill',
                isVisible: permissions.canViewHolidays()
              },
              {
                path: '/super-admins/parameters',
                label: 'Parâmetros',
                icon: 'bi bi-currency-exchange',
                isVisible: permissions.canViewParameters()
              },
              {
                path: '/super-admins/newsletter',
                label: 'Notícias',
                icon: 'bi bi-newspaper',
                isVisible: permissions.canViewNewsletter()
              },
              {
                path: '/super-admins/gift-card',
                label: 'Gift cards',
                icon: 'bi bi-gift-fill',
                isVisible: permissions.canViewGiftCard()
              },
              {
                path: '/super-admins/platform-logs',
                label: 'PlatformLogs',
                icon: 'bi bi-code-square',
                isVisible: permissions.canViewPlatformLogs()
              },
              {
                path: '/super-admins/promo-codes',
                label: 'Cód. Promocionais',
                icon: 'bi bi-0-square-fill',
                isVisible: permissions.canViewPromoCodes()
              },
              {
                path: '/super-admins/push-notification',
                label: 'Push Notification',
                icon: 'bi bi-bell-fill',
                isVisible: permissions.canViewPushNotification()
              },
              {
                path: '/super-admins/platform-alert',
                label: 'Alerta de plataforma',
                icon: 'bi bi-newspaper',
                isVisible: permissions.canViewPlatformAlert()
              },
              {
                path: '/super-admins/users',
                label: 'Administradores',
                icon: 'bi bi-person-vcard-fill',
                isVisible: permissions.canManageUsers()
              },
              {
                path: '/super-admins/brokers',
                label: 'Brokers',
                icon: 'bi bi-person-vcard-fill',
                isVisible: permissions.canManageBrokers()
              }
            ]
          },
          {
            path: '/banker-promo-code',
            label: 'Cód. de assessores',
            icon: 'bi bi-0-square-fill',
            isVisible: permissions.canViewBankerPromoCode()
          }
        ]
      }
    ])

    return {
      menuItems,
      loadAssetWhiteLabel
    }
  }
}
</script>

<template>
  <div class="col-lg-3 col-xl-2 d-flex flex-column menu">
    <div class="row">
      <div class="float-start">
        <app-img class="img-responsive logo" :src="loadAssetWhiteLabel('logo.svg')" alt="logo" />
      </div>
    </div>

    <menu-item v-for="item in menuItems" :key="item.label" :item="item" />
  </div>
</template>

<style lang="scss">
.menu {
  padding: 50px 18px 20px 18px;
  height: 100vh;
  border-right: solid 1px var(--fifth-color);
  position: fixed;
  overflow-y: auto;

  .logo {
    max-width: 80%;
    max-height: 60px;
  }

  label {
    font-size: 12px;
    color: #b2b1b1;
  }

  ul {
    list-style-type: none;
    margin: 0;

    li {
      margin: 1px 0;
      padding: 16px 10px 16px 22px;
      color: var(--sixth-color);
      border-radius: $base-radius-button;
      position: relative;
      cursor: pointer;

      &:hover,
      &:focus {
        background-color: var(--button-background);
        color: var(--button-color);
        @include transition();
      }

      &:after {
        content: '';
        background: url(@/assets/img/svg/arrow-menu.svg) no-repeat center right;
        top: 22px;
        right: 8px;
        height: 10px;
        width: 10px;
        position: absolute;
      }

      &.active {
        background-color: var(--button-background);
        color: var(--button-color);
        @include transition();
      }
    }
  }

  .accordion {
    .accordion-item {
      background-color: transparent;
      border: none;

      .accordion-click {
        margin: 1px 0;
        padding: 16px 10px 16px 22px;
        color: var(--sixth-color);
        border-radius: $base-radius-button;
        background-color: transparent;
        box-shadow: none;
        border-radius: 9px;

        &:hover,
        &:focus {
          background-color: var(--button-background);
          color: var(--button-color);
          @include transition();
        }

        &:after {
          content: '';
          background: url(@/assets/img/svg/arrow-menu.svg) no-repeat center right;
          top: 22px;
          right: 8px;
          height: 10px;
          width: 10px;
          position: absolute;
          transition: transform 0.2s ease-in-out;
        }

        &:not(.collapsed) {
          &::after {
            transform: rotate(-90deg);
          }
        }
      }
    }
  }
}
</style>
@/composables/utils/loadAsset
